import Api from './Api.js';

// console.log(Api);
// const instancedApi = new Api('api/test');
// console.log(instancedApi);
// console.log("user service  out of definition");

class UserService extends Api {
	getUser = (userId) => {
		return this.API.post(`${this.controller}/Get?userId=${userId}`);
	};

	search = (model) => {
		let url = `${this.controller}/Search`;

		var searchParams = new URLSearchParams();

		searchParams.append('Name', model.name || '');

		searchParams.append('Email', model.email || '');

		searchParams.append('Phone', model.phone || '');

		searchParams.append('Role', model.tipo || 0);

		searchParams.append('Gender', model.genero || 0);

		searchParams.append('ResultLimit', model.limit || 20);

		var queryUrl = '?' + searchParams.toString();
		url += queryUrl;

		return this.API.get(url);
	};

	searchBasic = (model) => {
		let url = `${this.controller}/SearchBasic`;

		var searchParams = new URLSearchParams();

		searchParams.append('Name', model.name || '');

		searchParams.append('Email', model.email || '');

		searchParams.append('Phone', model.phone || '');

		searchParams.append('Role', model.tipo || '');

		searchParams.append('ResultLimit', model.limit || 20);

		var queryUrl = '?' + searchParams.toString();
		url += queryUrl;

		return this.API.get(url);
	};

	activate = (id) => {
		return this.API.post(`${this.controller}/ActivateAccount?userId=${id}`);
	};

	recoverPassword = (email) => {
		return this.API.post(`api/Security/RecoverPassword`, { userName: email });
	};

	updatePassword = (model) => {
		return this.API.post(`api/Security/UpdatePassword`, model);
	};

	upsert = (model) => {
		let url = `${this.controller}/Upsert`;

		return this.API.post(url, model);
	};

	register = (model) => {
		let url = `${this.controller}/CreateUser`;
		return this.API.post(url, model);
	}

	getUserData = () => {
		return this.API.get(`${this.controller}/GetUser`);
	};

	getUserBillInfo = () => {
		return this.API.get(`${this.controller}/GetUserBillInfo`);
	};

	saveUserBillInfo = (model) => {
		return this.API.post(`${this.controller}/PostUserBillInfo`, model);
	};

	getPrincipalUsers = () => {
		return this.API.get(`${this.controller}/GetPrincipalUsers`);
	};

	saveReminderSettings = (model) => {
		return this.API.post(`${this.controller}/UpdateReminderSettings`, model);
	};
}

let userService = new UserService('api/User');

export default userService;